import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import { setCookie } from '@cookies'
import font from '@helpers/font'
import Icon from '@system/icon'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  geoBannerRoot: {
    position: 'relative',
    height: '50px',
    backgroundColor: theme.palette.legacy.secondary,
    color: (props) =>
      (props.color && props.color.color) || theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...makeResponsiveFontSizes(20, 8),
  },
  link: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    color: 'inherit',
    fontSize: 'inherit',
    [theme.breakpoints.down('xs')]: {
      margin: '0 3rem',
      textAlign: 'center',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
    fontWeight: theme.typography.fontWeightLight,
    ...makeResponsiveFontSizes(36, 12),
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}))

const GeoBanner = (props) => {
  const classes = useStyles(props.blok)
  const { text } = props.blok
  const [open, setOpen] = useState(true)

  const handleBannerClose = () => {
    setCookie('accbd', true, 30)
  }

  return (
    <SbEditable content={props.blok}>
      <Collapse in={open}>
        <Box className={classes.geoBannerRoot}>
          <a href={props.suggestedUrl} className={classes.link}>
            {text}
          </a>
          <IconButton
            className={classes.closeIcon}
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => {
              handleBannerClose()
              setOpen(false)
            }}
          >
            <Icon styles={classes.searchIconSvg} fontSize="inherit">
              CloseIcon
            </Icon>
          </IconButton>
        </Box>
      </Collapse>
    </SbEditable>
  )
}

export default GeoBanner
